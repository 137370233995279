import { Context } from "~/public_html/dfm5";

/**
 * Gets a set of user properties from the Context object
 * for use in PostHog events.
 *
 * @returns {{
 *  user_id: number|undefined,
 *  user_status: number|undefined,
 *  user_type: string,
 *  school_id: string|undefined,
 *  school_name: string|undefined
 * }}
 */
export function getUserPropertiesForEvent() {
  const user = Context?.user ?? null;
  const properties = user
    ? {
        user_id: user.uid,
        user_status: user.status,
        user_type: user.type,
        school_id: user.sid,
        school_name: user._school?.name ?? "",
      }
    : { user_type: "guest" };
  return properties;
}

/**
 * Builds a set of course properties for use in PostHog
 * events.
 *
 * @param {string} authorName
 * @param {{ coid: number, name: string, type: string }} course
 * @param {boolean} courseIsPremade
 * @returns {{
 *  course_id: number,
 *  course_publisher: string,
 *  course_name: string,
 *  course_type: string,
 *  course_is_premade: boolean
 * }}
 */
export function getCoursePropertiesForEvent(
  authorName,
  course,
  courseIsPremade,
) {
  return {
    course_id: course.coid,
    course_publisher: authorName,
    course_name: course.name,
    course_type: course.type,
    course_is_premade: courseIsPremade,
  };
}

/**
 * Builds a set of course module properties for use in
 * PostHog events.
 *
 * @param {{ cmid: number, name: string, o: number }} module
 * @returns {{
 *  module_id: number,
 *  module_name: string,
 *  module_position: number
 * }}
 */
export function getCourseModulePropertiesForEvent(module) {
  return {
    module_id: module.cmid,
    module_name: module.name,
    module_position: module.o + 1,
  };
}

/**
 * Builds a set of course unit properties for use in PostHog
 * events.
 *
 * @param {{ cuid: number, name: string, o: number }} unit
 * @returns {{
 *  unit_id: number,
 *  unit_name: string,
 *  unit_position: number
 * }}
 */
export function getCourseUnitPropertiesForEvent(unit) {
  return {
    unit_id: unit.cuid,
    unit_name: unit.name,
    unit_position: unit.o + 1,
  };
}

/**
 * Builds a set of browse course filter properties for
 * use in PostHog events.
 *
 * @param {number} count The number of courses with current filters applied.
 * @param {{ text: string, publisher: string[], type: string[] }} filters The current filters applied.
 * @returns {{
 *  browse_courses_text_filter: string,
 *  browse_courses_publisher_filter: string | string[],
 *  browse_courses_type_filter: string | string[],
 *  browse_courses_count: number
 * }}
 */
export function getBrowseCoursesFilterPropertiesForEvent(count, filters) {
  return {
    browse_courses_text_filter: filters.text ? filters.text : "n/a",
    browse_courses_publisher_filter: filters.publisher.length ? filters.publisher : "n/a",
    browse_courses_type_filter: filters.type.length ? filters.type : "n/a",
    browse_courses_list_count: count,
  };
}
